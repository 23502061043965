<template>
  <div class="homePageBox">
    <div class="showPartition">
      <div class="leftPartition clearfix">
        <div class="certificateRelated">
          <h2>数字证书</h2>
          <dl @click="goRouter('certApply')">
            <dt>
              <svg-icon icon-class="cert-apply" />证书申请
            </dt>
            <dd>可以根据需要选择具体项目</dd>
          </dl>
          <dl @click="goRouter('certRenewal')">
            <dt>
              <svg-icon icon-class="cert-renewal" />证书续期
            </dt>
            <dd>证书临近过期，可以申请续期</dd>
          </dl>
        </div>
        <div class="recentBusiness">
          <h5>数字证书业务单</h5>
          <div class="hasNotBusiness" v-if="transaction_list.length<=0&&Object.keys(options).length===0">
            <dl>
              <dt><img src="@/assets/image/personCenter/emptyState.png"></dt>
              <dd>未办理过业务，<el-button @click="goRouter('certApply')" type="text">去申请Ukey证书</el-button>
              </dd>
            </dl>
          </div>
          <div v-else class="transBox">
            <el-table ref="tableList1" :data="transaction_list" style="width: 100%;" header-row-class-name="tableHeadTitle" v-loading="businessLoad" @filter-change="filterTag"
              :filter-multiple='false'>
              <el-table-column prop="transactionNo" label="业务单号" width="220" show-overflow-tooltip></el-table-column>
              <el-table-column prop="createdDate" label="申请时间" min-width="100" show-overflow-tooltip></el-table-column>
              <el-table-column prop="name" label="业务单名称" min-width="200" show-overflow-tooltip></el-table-column>
              <el-table-column prop="type" label="业务类型" min-width="100" :filters="transTypeOption" :column-key="'businessType'" :filter-multiple='false'>
                <template slot-scope="scope">
                  <span>
                    {{scope.row.type |transactionTypeFilter}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="业务单状态" width="120" column-key="status" :filters="transStatusOption" :filter-multiple='false'>
                <template slot-scope="scope">
                  <span :class="'statusColor statusColor' + scope.row.status">{{scope.row.status | certTransStatusFilter}}</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" prop="status" label="操作" width="200">
                <template slot-scope="scope">
                  <el-button @click="getOpenPage({name: businessType[scope.row.type],transId: scope.row.id})" type="text" v-show="scope.row.status<11||scope.row.status===14">继续办理</el-button>
                  <el-button @click="getBusinessInfo(scope.row.id)" type="text">详情</el-button>
                  <el-button @click="rewriteUkey(scope.row.id)" type="text" v-if="(scope.row.type===2||scope.row.type===3)&&scope.row.status===11">重新写入</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button @click="$router.push({name:'serviceCenter', query: { type: 1 }})" v-if="initialNumber===3" type="text">>>查看所有业务单</el-button>
          </div>
        </div>
      </div>
      <div class="rightPartition" v-loading="personalLoad">
        <div class="personInfo">
          <div class="administratorInfo" @click="$router.push({ name: 'account' })">
            <div class="isCertified">
              <img :src="require(`@/assets/image/personCenter/${personalData.verified ? 'certified.png' : 'notCertified.png'}`)">
            </div>
            <dl>
              <dt>{{personalData.name || '管理员信息'}}</dt>
              <!-- <dd>{{personalData.bindCustomer?'企业用户':'普通用户'}}</dd> -->
            </dl>
            <el-button type="primary" size="small" v-if="!personalData.verified" @click.stop="completeInfo(true)">管理员认证</el-button>
          </div>
          <p><span>身份证号</span>{{personalData.identNo || '- -'}}</p>
          <p><span>手机号</span><u>{{mobile || '- -'}}</u><i @click="showHidden" v-if="mobile">
              <svg-icon :icon-class="isEncryption?'visible':'invisible'" />
            </i></p>
          <p><span>邮箱</span>{{personalData.email || '- -'}}</p>
        </div>
        <!-- <div class="companyInfo">
          <h2 v-if="!personalData.bindCustomer">升级为企业用户，体验豸信证书集成服务</h2>
          <el-button type="text" v-if="!personalData.bindCustomer" @click="judgmentAuth">认证企业</el-button>
          <h3 v-if="personalData.bindCustomer">{{personalData.defaultCustomerName}}</h3>
          <el-button type="primary" round size="mini" v-if="personalData.bindCustomer" @click="$router.push({ name: 'enterprise' })">更多</el-button>
        </div> -->
      </div>
    </div>
    <div class="showPartition">
      <div class="leftPartition verticalRow">
        <div class="reportlist transBox" v-loading="reportLoad">
          <h2>验签报告</h2>
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="电子版报告" name="electron">
              <div class="signatureList">
                <el-table ref="tableList2" :data="reportlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="reportFilterTag" :filter-multiple='false'>
                  <el-table-column prop="transactionNo" label="业务单号" width="220"></el-table-column>
                  <el-table-column prop="createdDate" label="申请时间" width="220"></el-table-column>
                  <el-table-column prop="transactionName" label="业务单名称" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="transactionType" label="业务类型" width="120">
                    <template slot-scope="scope">
                      <span>{{scope.row.transactionType===0?'电子版':'纸质版'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="amount" label="金额（元）" width="120">
                    <template slot-scope="scope">
                      <span>{{'¥'+scope.row.amount||'- -'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="transactionStatus" label="业务单状态" column-key="transactionStatus" :filters="reportElectronStatus" :filter-multiple='false' width="120">
                    <template slot-scope="scope">
                      <span :class="'statusColor statusColor'+scope.row.transactionStatus">{{scope.row.transactionStatus|reportStatus}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="" label="操作" width="180">
                    <template slot-scope="scope">
                      <el-button type="text" v-if="scope.row.transactionStatus===1" @click="goPayment(scope.row.transactionId)">立即付款</el-button>
                      <span v-else></span>
                      <el-button type="text" v-if="scope.row.transactionStatus===1" @click="cancelReport(scope.row.transactionId)">取消</el-button>
                      <el-button type="text" v-else-if="scope.row.transactionType===0&&scope.row.transactionStatus===7" @click="downloadPdf(scope.row.reportUrl)">下载报告</el-button>
                      <el-button @click="getReportInfo(scope.row.transactionId)" type="text">详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="纸质版报告" name="paper">
              <div class="signatureList">
                <el-table ref="tableList3" :data="reportlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="reportFilterTag" :filter-multiple='false'>
                  <el-table-column prop="transactionNo" label="业务单号" width="220"></el-table-column>
                  <el-table-column prop="createdDate" label="申请时间" width="220"></el-table-column>
                  <el-table-column prop="transactionName" label="业务单名称" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="transactionType" label="业务类型" width="120">
                    <template slot-scope="scope">
                      <span>{{scope.row.transactionType===0?'电子版':'纸质版'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="amount" label="金额（元）" width="120">
                    <template slot-scope="scope">
                      <span>{{'¥'+scope.row.amount||'- -'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="transactionStatus" label="业务单状态" column-key="transactionStatus" :filters="reportPaperStatus" :filter-multiple='false' width="120">
                    <template slot-scope="scope">
                      <span :class="'statusColor statusColor'+scope.row.transactionStatus">{{scope.row.transactionStatus|reportStatus}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="" label="操作" width="180">
                    <template slot-scope="scope">
                      <el-button type="text" v-if="scope.row.transactionStatus===1" @click="goPayment(scope.row.transactionId)">立即付款</el-button>
                      <span v-else></span>
                      <el-button type="text" v-if="scope.row.transactionStatus===1" @click="cancelReport(scope.row.transactionId)">取消</el-button>
                      <span v-else></span>
                      <el-button @click="getReportInfo(scope.row.transactionId)" type="text">详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-button @click="$router.push({name:'serviceCenter', query: { type: 4 }})" v-if="reportLength===3" type="text">>>查看所有业务单</el-button>
        </div>
        <div class="applicationsBox" v-loading="integrationsLoad">
          <h2>集成应用</h2>
          <el-tabs v-model="integrationName" type="card" @tab-click="orderChange">
            <el-tab-pane label="云证书API集成服务（实名+证书申请）" name="api">
              <div class="signatureList transBox">
                <el-table ref="tableList4" :data="cloudlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="orderFilterTag" :filter-multiple='false'>
                  <el-table-column prop="orderNo" label="业务单号" width="220"></el-table-column>
                  <el-table-column prop="orderTime" label="申请时间" width="220"></el-table-column>
                  <el-table-column prop="companyName" label="申请主体" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="productSpecs" label="规格" width="120">
                    <template slot-scope="scope">
                      <span>{{scope.row.productSpecs===0?'纯API版':'网页版'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="业务单状态" column-key="orderStatus" :filters="cloudStatus" :filter-multiple='false' width="180">
                    <template slot-scope="scope">
                      <span :class="'orderStatus orderStatus'+scope.row.status">{{scope.row.status|orderStatus}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="" label="操作" width="180">
                    <template slot-scope="scope">
                      <el-button type="text" v-if="scope.row.status===2" @click="goCloudPayment(scope.row.orderNo)">立即付款</el-button>
                      <span v-else></span>
                      <el-button @click="cancelCloudCert(scope.row.orderNo)" type="text" v-if="scope.row.status<=2">取消</el-button>
                      <span v-else></span>
                      <el-button @click="openCloudDetails(scope.row.orderNo)" type="text">详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-button @click="$router.push({name:'serviceCenter', query: { type: 2 }})" v-if="integrationLength===3" type="text">>>查看所有业务单</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="云证书API集成服务（证书申请）" name="apiCert">
              <div class="signatureList transBox">
                <el-table ref="tableList5" :data="cloudlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="orderFilterTag" :filter-multiple='false'>
                  <el-table-column prop="orderNo" label="业务单号" width="220"></el-table-column>
                  <el-table-column prop="orderTime" label="申请时间" width="220"></el-table-column>
                  <el-table-column prop="companyName" label="申请主体" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="productSpecs" label="规格" width="120">
                    <template slot-scope="scope">
                      <span>{{scope.row.productSpecs===0?'纯API版':'网页版'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="业务单状态" column-key="orderStatus" :filters="cloudStatus" :filter-multiple='false' width="180">
                    <template slot-scope="scope">
                      <span :class="'orderStatus orderStatus'+scope.row.status">{{scope.row.status|orderStatus}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="" label="操作" width="180">
                    <template slot-scope="scope">
                      <el-button type="text" v-if="scope.row.status===2" @click="goCloudPayment(scope.row.orderNo)">立即付款</el-button>
                      <span v-else></span>
                      <el-button @click="cancelCloudCert(scope.row.orderNo)" type="text" v-if="scope.row.status<=2">取消</el-button>
                      <span v-else></span>
                      <el-button @click="openCloudDetails(scope.row.orderNo)" type="text">详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-button @click="$router.push({name:'serviceCenter', query: { type: 2 }})" v-if="integrationLength===3" type="text">>>查看所有业务单</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="网络身份认证" name="network">
              <div class="signatureList transBox">
                <el-table ref="tableList6" :data="cloudlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="orderFilterTag" :filter-multiple='false'>
                  <el-table-column prop="orderNo" label="订单号" width="180" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="productName" label="产品名称" width="130" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <span>{{scope.row.productType|productType}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="productName" label="类型" show-overflow-tooltip column-key="productCode" :filters="productDescription" :filter-multiple='false' width="140"></el-table-column>
                  <el-table-column prop="companyName" label="集成企业" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="orderType" label="业务类型" column-key="orderType" :filters="buyType" :filter-multiple='false' width="100">
                    <template slot-scope="scope">
                      <span>{{scope.row.orderType|orderType}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="productSpecs" label="规格" width="80">
                    <template slot-scope="scope">
                      <span>{{scope.row.productSpecs===0?'纯API版':'网页版'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="times" label="次数" width="80"></el-table-column>
                  <el-table-column prop="status" label="状态" column-key="orderStatus" :filters="productStatus" :filter-multiple='false' width="100">
                    <template slot-scope="scope">
                      <span :class="'orderStatus orderStatus'+scope.row.status">{{scope.row.status|orderStatus}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="price" label="订单金额" width="80">
                    <template slot-scope="scope">
                      <span>{{'¥'+scope.row.price||'- -'}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="orderTime" label="下单时间" width="160"></el-table-column>
                  <el-table-column prop="" label="操作" width="160">
                    <template slot-scope="scope">
                      <el-button type="text" v-if="scope.row.status===2" @click="goOrderPayment(scope.row.orderNo)">立即付款</el-button>
                      <span v-else></span>
                      <el-button @click="cancelCloudCert(scope.row.orderNo)" type="text" v-if="scope.row.status===2">取消</el-button>
                      <span v-else></span>
                      <el-button @click="openDetails(scope.row.orderNo,'identityRealName')" type="text">详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-button @click="$router.push({name:'serviceCenter', query: { type: 3 }})" v-if="integrationLength===3" type="text">>>查看所有业务单</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="rightPartition">
        <h2 class="title">热门产品</h2>
        <div class="popularProducts">
          <dl @click="$router.push({ name: 'product-orgCert' })">
            <dt>机构证书</dt>
            <dd>用来证明单位、组织在互联网上的数字身份</dd>
          </dl>
          <dl @click="$router.push({ name: 'product-personCert' })">
            <dt>个人证书</dt>
            <dd>用以标识个人在网络中的数字身份</dd>
          </dl>
          <dl @click="goNewOrder">
            <dt>网络身份认证服务</dt>
            <dd>提供网络身份认证服务</dd>
          </dl>
        </div>
      </div>
    </div>
    <v-detail ref="detailInfo" @getlistData="getDataList"></v-detail>
    <v-invoiveImgShow ref="invoiveImgShow"></v-invoiveImgShow>
    <UkeyLogin v-model="ukeyLoginShow" @logined="ukeyLogined" :Ukey="Ukey" :certSn="Ukey.certSn" />
    <div class="processTips" v-if="renewaling">
      <div class="process">
        <div class="icon">
          <img src="@/assets/icons/service/loading-icon.png">
        </div>
        <div class="tt">证书{{transaction_list.type===3?'更新':'变更'}}中</div>
        <div class="hint">提示：请不要关闭浏览器，不要拔出Ukey，在此界面等待{{transaction_list.type===3?'更新':'变更'}}完毕，以免造成证书{{transaction_list.type===3?'更新':'变更'}}失败</div>
      </div>
    </div>
    <v-reportDetails ref="reportDetails" />
    <v-cloudCertDetails ref="cloudCertDetails" />
    <v-orderDetails ref="orderDetails" />

    <v-personInfo ref="personInfo" :isPersonAuth="isPersonAuth" />
  </div>
</template>
<script>
import { Ukey } from './../components/ukey'
import invoiveImgShow from '../components/invoiveImgShow'
import detail from './../serviceCenter/transactionDetail.vue'
import orderDetails from './../serviceCenter/orderDetails.vue'
import UkeyLogin from './../../service/components/ukeyLogin.vue'
import reportDetails from './../serviceCenter/reportDetails.vue'
import personInfo from '@/views/userInfo/account/personInfo.vue'
import cloudCertDetails from './../serviceCenter/cloudCertDetails.vue'
import {
  certTransStatus,
  transactionType,
  orderStatus,
  reportStatus,
  productType,
  orderType
} from '@/utils/typeMatch.js'
import {
  transTypeOption,
  transStatusOption,
  reportPaperStatus,
  reportElectronStatus,
  cloudStatus,
  productStatus,
  buyType
} from '@/utils/options'
export default {
  data() {
    return {
      personalData: {},
      type: 'personal',
      businessLoad: false,
      ukeyLoginShow: false,
      renewaling: false,
      transactionId: '',
      certData: '',
      options: {},
      transaction_list: [],
      Ukey: null,
      transTypeOption: transTypeOption,
      transStatusOption: transStatusOption,
      integrations: [],
      mobile: '',
      encryptionMobile: '',
      isEncryption: false,
      personalLoad: false,
      integrationsLoad: false,
      businessType: {
        1: 'certApply',
        2: 'certChange',
        3: 'certRenewal',
        4: 'certCancel',
        7: 'certUnlock',
        9: 'certReissue'
      },
      initialNumber: 0,
      activeName: 'electron',
      reportLoad: false,
      reportlist: [],
      reportPaperStatus: reportPaperStatus,
      reportElectronStatus: reportElectronStatus,
      transactionType: 0,
      reportLength: 0,
      integrationName: 'api',
      productSpecs: 1,
      productType: 1,
      cloudStatus: cloudStatus,
      cloudlist: [],
      integrationLength: 0,
      productDescription: [],
      productStatus: productStatus,
      isPersonAuth: '',
      buyType: buyType
    }
  },
  filters: {
    certTransStatusFilter: certTransStatus,
    transactionTypeFilter: transactionType,
    orderStatus,
    reportStatus,
    productType,
    orderType
  },
  components: {
    'v-detail': detail,
    'v-invoiveImgShow': invoiveImgShow,
    UkeyLogin,
    'v-orderDetails': orderDetails,
    'v-reportDetails': reportDetails,
    'v-cloudCertDetails': cloudCertDetails,
    'v-personInfo': personInfo
  },
  created() {
    this.Ukey = new Ukey(this)
    this.getInfo()
  },
  beforeDestroy() {
    if (document.querySelector('#personContainer')) {
      document.querySelector('#personContainer').setAttribute('style', '')
    }
  },
  mounted() {
    document
      .querySelector('#personContainer')
      .setAttribute('style', 'display: flex;') //变更flex布局，固定宽度，滚动展示
    this.getDataList()
    this.getReportList()
    this.getProductCodeList()
    this.getIntegrationList(this.$api.getOrderList)
  },
  methods: {
    judgmentAuth() {
      if (!this.personalData.verified) {
        this.$confirm('请先完成管理员认证', '提示', {
          confirmButtonText: '好的',
          showCancelButton: false,
          type: 'warning'
        })
          .then(() => {})
          .catch(() => {})
      } else {
        this.completeInfo(false)
      }
    },
    completeInfo(item) {
      this.isPersonAuth = item
      this.$refs.personInfo.realNameVisible = true
    },
    // showModule (url) {
    //   this.$router.push({ path: url })
    // },
    getAuth() {
      this.$bus.$on('afterLogin', () => {
        this.getInfo()
      })
      this.$confirm(
        '当前账号未完成管理员实名认证，实名之后才可办理业务。',
        '提示',
        {
          confirmButtonText: '实名认证',
          cancelButtonText: '暂不认证',
          type: 'warning'
        }
      )
        .then(() => {
          this.$router.push({ name: 'personHome' })
        })
        .catch(() => {})
    },
    getInfo() {
      this.personalLoad = true
      this.$api
        .getAccountInfo()
        .then(res => {
          this.personalLoad = false
          this.personalData = res.data
          this.mobile = this.personalData.mobile
          this.encryptionMobile = this.personalData.mobile
          if (res.data.isTipWrong) {
            // 管理员重复实名，进行提示
            this.$confirm(res.data.wrongInfo, '提示', {
              confirmButtonText: '好的',
              showCancelButton: false,
              type: 'warning'
            })
              .then(() => {})
              .catch(() => {})
          }
        })
        .catch(() => {
          this.personalLoad = false
        })
    },
    showHidden() {
      if (this.isEncryption) {
        this.mobile = this.encryptionMobile
      } else {
        this.mobile = this.$store.state.user.userMobile
      }
      this.isEncryption = !this.isEncryption
    },
    getDataList() {
      this.businessLoad = true
      let data = {
        pageIndex: 1,
        pageSize: 3
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api.getTransactionListPage(data).then(res => {
        this.businessLoad = false
        this.transaction_list = res.data.records
        this.total = res.data.total
        this.pager = res.data.pages
        if (Object.keys(this.options).length === 0) {
          this.initialNumber = res.data.records.length
        }
      })
    },
    filterTag(value) {
      for (let key in value) {
        if (this.options[key] && this.options[key].length === 0) {
          this.options[key] = ''
        } else {
          this.options[key] = value[key].join(',')
        }
      }
      this.getDataList()
    },
    getOpenPage(item) {
      let newPage = this.$router.resolve({
        name: item.name,
        query: { transId: item.transId }
      })
      window.open(newPage.href, '_blank')
    },
    getBusinessInfo(item) {
      this.$refs.detailInfo.getDetail(item)
    },
    goRouter(name) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.verify(name)
      })
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          this.$router.push({ name })
        } else {
          this.$confirm(
            '当前账号未完成管理员实名认证，实名之后才可办理业务。',
            '提示',
            {
              confirmButtonText: '实名认证',
              cancelButtonText: '暂不认证',
              type: 'warning'
            }
          )
            .then(() => {
              this.isPersonAuth = true
              this.$refs.personInfo.realNameVisible = true
            })
            .catch(() => {})
        }
      })
    },
    getInvoiceQrCode(transId, order, enablePlain, enableVat) {
      this.$refs.invoiveImgShow.showVat = enableVat
      this.$refs.invoiveImgShow.eInvoice = enablePlain
      if (!enablePlain && enableVat) {
        this.$refs.invoiveImgShow.infoIsShow = false
      }
      this.$refs.invoiveImgShow.getShow(transId)
    },
    ukeyLogined() {},
    rewriteUkey(id) {
      this.transactionId = id
      this.Ukey.connect()
        .then(() => {
          this.ukeyLogined = () => {
            this.renewaling = true
            this.getRemakeCert()
          }
          this.ukeyLoginShow = true
        })
        .catch(err => {
          if (err.message) this.$message.error(err.message)
        })
    },
    getRemakeCert() {
      this.renewaling = true
      this.$api
        .getRemakeCert({
          transId: this.transactionId,
          certSn: this.Ukey.certSn
        })
        .then(res => {
          this.certData = res.data
          this.Ukey.writeCert(this.certData)
            .then(res => {
              this.renewaling = false
              if (res) {
                this.$message({
                  type: 'success',
                  message: '写入成功！',
                  onClose: () => {}
                })
              } else {
                if (res !== undefined) this.$message.error(res.message)
              }
            })
            .catch(err => {
              this.renewaling = false
              this.$message.error(err.message)
            })
        })
        .catch(() => {
          this.renewaling = false
        })
    },
    openDetails(orderNo) {
      this.$refs.orderDetails.orderNo = orderNo
      this.$nextTick(() => {
        this.$refs.orderDetails.getOrderDetails()
      })
    },
    handleClick(tab) {
      this.options = {}
      this.$refs.tableList2.clearFilter()
      this.$refs.tableList3.clearFilter()
      if (tab.name === 'electron') {
        this.transactionType = 0
      } else {
        this.transactionType = 1
      }
      this.getReportList()
    },
    reportFilterTag(value) {
      for (let key in value) {
        if (this.options[key] && this.options[key].length === 0) {
          this.options[key] = ''
        } else {
          this.options[key] = value[key].join(',')
        }
      }
      this.getReportList()
    },
    getReportList() {
      this.reportLoad = true
      let data = {
        transactionType: this.transactionType,
        pageIndex: 1,
        pageSize: 3
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api
        .getReportPage(data)
        .then(res => {
          this.reportLoad = false
          if (res.code === '0') {
            this.total = res.data.total || 0
            this.reportlist = res.data.records
            if (Object.keys(this.options).length === 0) {
              this.reportLength = res.data.records.length
            }
          }
        })
        .catch(() => {
          this.reportLoad = false
        })
    },
    getReportInfo(transactionId) {
      this.$refs.reportDetails.transactionId = transactionId
      this.$refs.reportDetails.getReportDetails(transactionId)
    },
    cancelReport(transactionId) {
      this.$confirm('确认取消该业务单吗？', '取消确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.reportLoad = true
        this.$api
          .reportCancel({ transactionId: transactionId })
          .then(res => {
            this.reportLoad = false
            if (res.code === '0') {
              this.$message.success('取消成功')
              this.getReportList()
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(() => {
            this.reportLoad = false
          })
      })
    },
    goPayment(transactionId) {
      this.$router.push({
        name: 'verifyReport',
        query: { transactionId: transactionId }
      })
    },
    downloadPdf(url) {
      window.open(url, '_blank')
    },
    getProductCodeList() {
      this.integrationsLoad = true
      this.$api
        .getCodeList({ productType: 0 })
        .then(res => {
          this.integrationsLoad = false
          this.productDescription = res.data
        })
        .catch(() => {
          this.integrationsLoad = false
        })
    },
    orderFilterTag(value) {
      for (let key in value) {
        if (this.options[key] && this.options[key].length === 0) {
          this.options[key] = ''
        } else {
          this.options[key] = value[key].join(',')
        }
      }
      this.orderClassification()
    },
    orderChange() {
      this.options = {}
      this.$refs.tableList4.clearFilter()
      this.$refs.tableList5.clearFilter()
      this.$refs.tableList6.clearFilter()
      this.orderClassification()
    },
    orderClassification() {
      let fn
      if (this.integrationName === 'api') {
        this.productSpecs = 1
        this.productType = 1
        fn = this.$api.getOrderList
      } else if (this.integrationName === 'apiCert') {
        this.productSpecs = 0
        this.productType = 1
        fn = this.$api.getOrderList
      } else {
        this.productType = 0
        fn = this.$api.getOrderList
      }
      this.getIntegrationList(fn)
    },
    getIntegrationList(fn) {
      this.integrationsLoad = true
      let data = {
        productType: this.productType,
        productSpecs: this.productSpecs,
        pageIndex: 1,
        pageSize: 3
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      fn(data)
        .then(res => {
          this.integrationsLoad = false
          this.cloudlist = res.data.records
          if (Object.keys(this.options).length === 0) {
            this.integrationLength = res.data.records.length
          }
        })
        .catch(() => {
          this.integrationsLoad = false
        })
    },
    goCloudPayment(orderNo) {
      this.$store.commit('user/setOrderNo', orderNo)
      this.$router.push({
        name: 'cloudCertificate',
        query: { type: this.productType, specs: this.productSpecs }
      })
    },
    openCloudDetails(orderNo) {
      this.$refs.cloudCertDetails.orderNo = orderNo
      this.$refs.cloudCertDetails.getCloudDetails()
    },
    goOrderPayment(orderNo) {
      this.$store.commit('user/seAppId', '')
      this.$store.commit('user/setOrderNo', orderNo)
      this.$router.push({ name: 'identityAuth' })
    },
    cancelCloudCert(orderNo) {
      this.$confirm(`确定取消申请？`, '提示', {
        confirmButtonText: '确定',
        showCancelButton: '取消',
        type: 'warning'
      })
        .then(() => {
          this.cancelOrder(orderNo)
        })
        .catch(() => {})
    },
    cancelOrder(orderNo) {
      this.detailsLoad = true
      this.$api
        .cancelOrder({ orderNo: orderNo })
        .then(res => {
          this.detailsLoad = false
          if (res.code === '0') {
            this.$message.success(res.msg)
            this.orderClassification()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {
          this.detailsLoad = false
        })
    },
    refreshOrder() {
      this.orderClassification()
    },
    refreshCloud() {
      this.orderClassification()
    },
    goNewOrder() {
      this.$store.commit('user/seAppId', '')
      this.$store.commit('user/setOrderNo', '')
      this.$router.push({ name: 'identityAuth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.homePageBox {
  margin: 16px;
}
.showPartition {
  display: flex;
  margin-bottom: 16px;
}
.leftPartition {
  flex: 7.2;
  background: #fff;
  padding-right: 16px;
  display: flex;
  height: fit-content;
}
.rightPartition {
  flex-shrink: 0;
  width: 502px;
  height: max-content;
  margin-left: 16px;
  background: #fff;
}
.certificateRelated {
  padding: 24px 48px 52px 24px;
  h2 {
    font-size: 16px;
    margin-bottom: 32px;
    color: #303b50;
    line-height: 21px;
  }
  dl {
    width: 300px;
    height: 112px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 16px;
    cursor: pointer;
    &:nth-last-child(2) {
      margin-bottom: 32px;
      background: linear-gradient(90deg, #2d6dfe 0%, #0044dc 100%);
    }
    &:last-child {
      background: linear-gradient(90deg, #21bbfe 0%, #0696e6 100%);
    }
  }
  dt {
    margin-bottom: 9px;
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
  }
  .svg-icon {
    font-size: 24px;
    margin-right: 6px;
    vertical-align: middle;
  }
  dd {
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
  }
}
.recentBusiness {
  flex: 1;
  padding: 30px 0 0 0;
  h5 {
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    font-weight: normal;
    margin-bottom: 29px;
  }
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table thead th {
    &:nth-child(4),
    &:nth-child(5) {
      cursor: pointer;
    }
  }
  ::v-deep .el-table td {
    padding: 17px 0;
    border-color: #dee0e9;
    cursor: default;
  }
  .el-button {
    padding: 0;
    margin-left: 0;
    margin-right: 10px;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    color: #e89e1c;
    background: #fef8ec;
    border-radius: 15px;
    border: 1px solid #fbbc4d;
    &.statusColor2 {
      color: #1c67f3;
      background: #dbe4ff;
      border: 1px solid #a9beff;
    }
    &.statusColor11 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.statusColor3,
    &.statusColor13 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.statusColor12 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
  a {
    display: block;
    font-size: 14px;
    color: #1c67f3;
    line-height: 14px;
    margin: 25px auto 0;
    text-align: center;
  }
}
.hasNotBusiness {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 67px;
  dl {
    text-align: center;
  }
  img {
    width: 60px;
  }
  dd {
    margin-top: 16px;
    font-size: 12px;
    color: #b0b4bd;
    line-height: 18px;
  }
  a {
    font-size: 12px;
    margin: 0;
    display: inline-block;
  }
  .el-button {
    font-size: 12px;
  }
}
.personInfo {
  height: 385px;
  padding: 40px 24px 56px 27px;
  border-bottom: 1px solid #eeeff5;
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-bottom: 26px;
    margin-left: 5px;
    user-select: none;
    &:last-child {
      margin-bottom: 0;
    }
  }
  u {
    display: inline-block;
    width: 91px;
    text-decoration: none;
  }
  span {
    color: #6c737b;
    display: inline-block;
    width: 88px;
  }
  i {
    margin-left: 17px;
    cursor: pointer;
  }
}
.administratorInfo {
  margin-bottom: 40px;
  display: flex;
  position: relative;
  cursor: pointer;
  dl {
    padding-top: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  dt {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    // margin-bottom: 8px;
    cursor: pointer;
    &:hover {
      color: $primaryColor;
    }
  }
  dd {
    width: 67px;
    height: 24px;
    background: #ffffff;
    border-radius: 15px;
    border: 1px solid #1c67f3;
    font-size: 12px;
    color: #1c67f3;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
  .el-button {
    position: absolute;
    right: 0;
    font-size: 12px;
    line-height: 16px;
  }
}
.isCertified {
  height: 60px;
  position: relative;
  img {
    width: 70px;
    margin-right: 16px;
  }
  span {
    display: inline-block;
    width: 70px;
    height: 24px;
    padding-left: 25px;
    background: #e8e8e8;
    border-radius: 2px;
    font-size: 11px;
    color: #929292;
    line-height: 24px;
    position: absolute;
    bottom: -2px;
    left: -5px;
    z-index: 0;
  }
}
.companyInfo {
  height: 79px;
  background: #f5fbff;
  position: relative;
  h2,
  h3 {
    font-size: 14px;
    line-height: 79px;
    font-weight: normal;
    padding-left: 80px;
    background: url('~@/assets/image/personCenter/enterprise.png') 32px 25px
      no-repeat;
    background-size: 30px;
    user-select: none;
  }
  h2 {
    color: #647699;
  }
  h3 {
    width: 100%;
    padding-right: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #303b50;
  }
  .el-button {
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -21px;
  }
  .el-button--mini {
    right: 16px;
    padding: 5px 15px;
    margin-top: -14px;
  }
}
.applicationsBox {
  background: #fff;
  padding: 24px 24px 58px;
  margin-bottom: 24px;
  h2 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
  }
  h5 {
    margin-top: 8px;
    font-size: 12px;
    color: #6c737b;
    line-height: 16px;
    font-weight: normal;
  }
  .orderStatus {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    border-radius: 15px;
    &.orderStatus1,
    &.orderStatus2 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.orderStatus3,
    &.orderStatus4 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.orderStatus5 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.orderStatus6 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
}
.applications {
  padding-top: 24px;
  dl {
    float: left;
    width: 262px;
    height: 120px;
    background: #f1f4fc;
    border-radius: 4px;
    margin-right: 32px;
    margin-bottom: 32px;
    padding: 24px 16px;
    cursor: pointer;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  dt {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    font-weight: 700;
  }
  dd {
    font-size: 12px;
    color: #303b50;
    margin-top: 16px;
  }
  i {
    margin-right: 24px;
  }
  span {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    border-radius: 15px;
    padding: 0 12px;
  }
  .orderStatus1,
  .orderStatus2 {
    color: #e89e1c;
    background: #fef8ec;
    border: 1px solid #fbbc4d;
  }
  .orderStatus3,
  .orderStatus4 {
    color: #52c41a;
    background: #eff9eb;
    border: 1px solid #d4f0c8;
  }
}
.notIntegrated {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 24px 24px 90px;
  background: #fff;
  h2 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
  }
  h5 {
    margin-top: 8px;
    font-size: 12px;
    color: #6c737b;
    line-height: 16px;
    font-weight: normal;
  }
  dl {
    padding-top: 60px;
    text-align: center;
  }
  img {
    width: 60px;
  }
  dd {
    margin-top: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #b0b4bd;
    line-height: 18px;
  }
}
.title {
  font-size: 16px;
  color: #303b50;
  line-height: 21px;
  padding: 16px 34px;
  position: relative;
  font-weight: normal;
  &::before {
    position: absolute;
    width: 3px;
    height: 16px;
    content: '';
    background: $primaryColor;
    top: 20px;
    left: 24px;
  }
}

.popularProducts {
  dl {
    border-top: 1px solid #dee0e9;
    padding: 32px 24px 24px;
    cursor: pointer;
    user-select: none;
  }
  dt {
    font-size: 16px;
    color: #303b50;
    line-height: 20px;
    cursor: pointer;
  }
  dd {
    margin-top: 16px;
    font-size: 14px;
    color: #8b939d;
    line-height: 19px;
    cursor: pointer;
  }
}

.reportlist,
.applicationsBox {
  padding: 32px;
  margin-bottom: 16px;
  background: #fff;
  h2 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin-bottom: 24px;
  }
  ::v-deep .el-tabs--card > .el-tabs__header {
    border: none;
  }
  ::v-deep .el-tabs__nav {
    border: none;
  }
  ::v-deep .el-tabs__item {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    border-radius: 2px;
    border: 1px solid #dee0e9;
    margin-right: 24px;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-left: 1px solid #dee0e9;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    color: #1c67f3;
    background: #f1f5ff;
    border-radius: 2px;
    border: none;
  }
  a {
    display: block;
    font-size: 14px;
    color: #1c67f3;
    line-height: 14px;
    margin: 25px auto 0;
    text-align: center;
  }
}
.signatureList {
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 8px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  ::v-deep .el-table th > .cell.highlight {
    color: $primaryColor;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    border-radius: 15px;
    &.statusColor1,
    &.statusColor2,
    &.statusColor5 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.statusColor0,
    &.statusColor3,
    &.statusColor4,
    &.statusColor6 {
      color: #1c67f3;
      background: #dbe4ff;
      border: 1px solid #a9beff;
    }
    &.statusColor-1 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
    &.statusColor7 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
  }
}
.verticalRow {
  flex-direction: column;
  background-color: #f1f3f7;
  padding-right: 0;
}
.transBox {
  > .el-button--text {
    display: block;
    padding: 0;
    margin: 25px auto 0;
  }
}
</style>